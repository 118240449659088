<template>
  <div style='font-size: 14rem !important;padding: 24rem 24rem 24rem 48rem;' class="details">
    <Card :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}" class="card" title="客户基础信息">
      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>家长姓名</span>
          <span>{{ data.parent_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>报名手机号</span>
          <span>{{ data.phone }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>学生姓名</span>
          <span>{{ data.student_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>客户与学生关系</span>
          <span>{{ data.relationship }}</span>
        </el-col>
      </el-row>
      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>家长联系方式</span>
          <span>{{ data.parent_contact }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>银行卡</span>
          <span>{{ data.bank_cark }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>开户行</span>
          <span>{{ data.bank_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>开户人</span>
          <span>{{ data.bank_user }}</span>
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>学生重大疾病历史</span>
          <span>{{ data.physiology }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>学生心理问题</span>
          <span>{{ data.psychology }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>学生犯罪前科</span>
          <span>{{ data.crime }}</span>
        </el-col>
      </el-row>
    </Card>

    <Card :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}" class="card" title="报名表信息">
      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>学生姓名</span>
          <span>{{ data.student_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>学生年龄</span>
          <span>{{ data.student_age }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>学生性别</span>
          <span>{{ data.student_sex }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>学生民族</span>
          <span>{{ data.student_nation }}</span>
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='12'>
          <span class='left_item'>学生户籍地址</span>
          <span>{{ data.student_register_province }}</span>-
          <span>{{ data.student_register_city }}</span>-
          <span>{{ data.student_register_area }}</span>
          <!--          <span v-if="data.student_register_address">- </span>-->
          <!--          <span>{{ data.student_register_address }}</span>-->
        </el-col>
        <el-col :span='12'>
          <span class='left_item'>学生身份证号</span>
          <span v-if="data.student_idcard">{{ showArr[0] ? data.student_idcard_full : data.student_idcard_full.replace(/^(\d{6})\d+(\d{3}[xX0-9]{1})$/, "$1******$2") }}</span>
          <img v-if="showArr[0]" :src="this.$OssAddress + 'yanjing.png'" alt="" style="width: 24rem;margin-left: 12rem" @click="show(0)">
          <img v-else :src="this.$OssAddress + 'yingcang.png'" alt="" style="width: 24rem;margin-left: 12rem" @click="show(0)">
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='12'>
          <span class='left_item'>学生通信地址</span>
          <span>{{ data.student_communication_province }}</span>-
          <span>{{ data.student_communication_city }}</span>-
          <span>{{ data.student_communication_area }}</span>-
          <span>{{ data.student_communication_address }}</span>
        </el-col>
        <el-col :span='12'>
          <span class='left_item'>邮政编码</span>
          <span>{{ data.student_postal_code }}</span>
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='12'>
          <span class='left_item'>学生就读途径</span>
          <span>{{ data.student_attend_road }}</span>
        </el-col>
        <el-col :span='12'>
          <span class='left_item'>家长身份证</span>
          <span v-if="data.student_attend_road">{{ showArr[1] ? data.parent_idcard_full : data.parent_idcard_full.replace(/^(\d{6})\d+(\d{3}[xX0-9]{1})$/, "$1******$2") }}</span>
          <img v-if="showArr[1]" :src="this.$OssAddress + 'yanjing.png'" alt="" style="width: 24rem;margin-left: 12rem" @click="show(1)">
          <img v-else :src="this.$OssAddress + 'yingcang.png'" alt="" style="width: 24rem;margin-left: 12rem" @click="show(1)">
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='12'>
          <span class='left_item'>初中毕业时间</span>
          <span>{{ data.student_attend_graduation }}</span>
        </el-col>
        <el-col :span='12'>
          <span class='left_item'>初中毕业班级</span>
          <span>{{ data.student_attend_graduation_class }}</span>
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='12'>
          <span class='left_item'>初中毕业班主任姓名</span>
          <span>{{ data.main_teacher_name }}</span>
        </el-col>
        <el-col :span='12'>
          <span class='left_item'>初中毕业班主任电话</span>
          <span>{{ data.main_teacher_phone }}</span>
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>中考总分预估</span>
          <span>{{ data.student_senior_achievement }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>预估分对应收费档位</span>
          <span>{{ data.senior_achievement_fee_level }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>初中是否留级</span>
          <span>{{ data.student_attend_repetition }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>初中是否休学</span>
          <span>{{ data.student_attend_suspend }}</span>
        </el-col>
      </el-row>

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>高中期望学习语种</span>
          <span>{{ data.student_high_languages }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>高中学习方向</span>
          <span>{{ data.study_type_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>高中专业方向</span>
          <span>{{ data.major_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>学生来源</span>
          <span>{{ data.source }}</span>
        </el-col>
      </el-row>
      <el-row :span='24' style='margin: 24rem 0'>
        <el-col v-if="data.related_stu!==''" :span='6'>
          <span class='left_item'>关联学生姓名</span>
          <span>{{ data.related_stu }}</span>
        </el-col>
      </el-row>
    </Card>

    <Card :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}" class="card" title="客户报名信息">
      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>订单号</span>
          <span>{{ data.order_number }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>订单状态</span>
          <span>{{ data.pay_status }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>服务名称</span>
          <span>{{ data.goods_pack_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>支付金额</span>
          <span>￥{{ data.money_actual_total }}</span>
        </el-col>
      </el-row>
<!--
      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>订单状态</span>
          <span>{{ data.pay_status }}</span>
        </el-col>
&lt;!&ndash;        <el-col :span='6'>
          <span class='left_item'>签约状态</span>
          <span>{{ data.sign_status }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>签约时间</span>
          <span>{{ data.sign_time }}</span>
        </el-col>&ndash;&gt;
      </el-row>-->

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>报名学校</span>
          <span>{{ data.sign_school_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>报名学校顺序号</span>
          <span>{{ data.school_sn }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>实际就读学校</span>
          <span>{{ data.study_school_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>报名年级</span>
          <span>{{ data.grade_name }}</span>
        </el-col>
      </el-row>

<!--      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>协议编号</span>
          <span>{{ data.agreement_sn }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>协议名称</span>
          <span>{{ data.purchase_contract_name }}</span>
        </el-col>
      </el-row>-->

      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>报名招生老师</span>
          <span>{{ data.sign_teacher_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>当前客户归属招生老师</span>
          <span>{{ data.belong_teacher_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>实际提成人</span>
          <span>{{ data.rebate_teacher_name }}</span>
        </el-col>
      </el-row>

<!--      <el-row :span='24' style='margin: 24rem 0'>
        <el-col :span='6'>
          <span class='left_item'>实际提成人</span>
          <span>{{ data.rebate_teacher_name }}</span>
        </el-col>
        <el-col :span='6'>
          <span class='left_item'>应提成金额</span>
          <span>￥{{ data.rebate_amount }}</span>
        </el-col>
        <el-col :span='6' v-if="data.is_rebate!==''">
          <span class='left_item'>是否提成</span>
          <span>{{ data.is_rebate }}</span>
        </el-col>
      </el-row>-->
    </Card>

    <Card :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}" v-show="data.refund.is_refund" class="card" title="退费信息">
      <el-form label-width="138rem">
        <el-form-item label="是否退费">
          {{ data.refund.is_refund === 1?"是":"否" }}
        </el-form-item>
        <el-form-item label="银行卡号">
          {{ data.refund.bank_card_number }}
        </el-form-item>
        <el-form-item label="开户行">
          {{ data.refund.opening_bank }}
        </el-form-item>
        <el-form-item label="开户人">
          {{ data.refund.account_holder }}
        </el-form-item>
        <el-form-item label="退费登记时间">
          {{ data.refund.refund_time }}
        </el-form-item>
        <el-form-item label="退费操作人">
          {{ data.refund.refund_name }}
        </el-form-item>
      </el-form>
    </Card>

    <Card :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}" class="card" title="备注记录">
      <table-template
          ref='teacher-table'
          :current-page='page'
          :table-config='tableConfig'
          :table-data='tableData'
          :total='total'
          @onChangePage='changeCurrentPage'>
      </table-template>
    </Card>

    <div class='footer'>
      <el-button type='primary' @click='()=>this.$router.back()'>返回</el-button>
      <el-button @click='remarkDialog=true'>填写备注</el-button>
      <el-button @click='onChange'>历史变更记录</el-button>
<!--      <el-button v-if="data.sign_status === '已签约'" @click="onSkip">查看协议内容</el-button>-->
    </div>

    <!--    添加备注-->
    <el-dialog :before-close='handleClose' :visible.sync='remarkDialog' title='添加备注' width='30%'>
      <span slot='footer' class='dialog-footer'>
        <el-input v-model='remark' maxlength='300' placeholder='请输入' resize='none' rows='8' show-word-limit style='margin-bottom: 24rem;margin-top: -24rem;width: 580rem' type='textarea'></el-input>
        <el-button type='primary' @click='onRemark'>保存</el-button>
        <el-button @click='handleClose'>取消</el-button>
      </span>
    </el-dialog>

    <!--    历史记录-->
    <l-history-dialog :historyData="list" :historyDialog="changeDialog" @close="()=>changeDialog=false"/>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import Card from "@/components/Card.vue";
import tableTemplate from "@/components/table/TableTemplate";

export default {
  _config:{
    route:{
      path:"look",
      meta:{"title":"查看"},

    }
  },
  name: 'look',
  data() { // 这里存放数据
    return {
      data: {
        refund:{}
      },
      showArr: [0, 0],
      page: 1,
      total: 0, // 总数
      search: null, // 搜索条件
      tableData: [], // 表格中的数据
      // 表格配置
      tableConfig: [{
        prop: 'remark',
        label: '备注内容',
      }, {
        prop: 'created_at',
        label: '备注时间',
        width: 200,
      }, {
        prop: 'creater_name',
        label: '备注人员',
        width: 200,
      }],
      remarkDialog: false,
      changeDialog: false,
      remark: '',
      list: [],
    };
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {
    Card,
    tableTemplate
  },
  props: {},
  // 方法集合
  methods: {
    show(val) {
      if (!val) {
        if (this.showArr[0] === 0) this.showArr[0] = 1
        else this.showArr[0] = 0
      } else {
        if (this.showArr[1] === 0) this.showArr[1] = 1
        else this.showArr[1] = 0
      }
      this.$forceUpdate()
    },
    getData() {
      this.$_register.get('api/recruit/account-manage/info-remark-list', { params: { sign_info_id: this.$route.query.id, page: this.page } }).then(res => {
        this.total = res.data.data.page.total;
        this.tableData = res.data.data.list;
      });
    },
    changeCurrentPage(e) { // 分页被更改
      this.page = e;
      this.getData();
    },
    handleClose() { // 备注弹窗
      this.remarkDialog = false;
      this.remark = ''
    },
    onRemark() { // 新增备注备注
      this.$_register.post('api/recruit/account-manage/add-info-remark', {
        info_id: this.$route.query.id, remark: this.remark,
      }).then(res => {
        if (res.data.status === 0) {
          this.getData();
          this.remarkDialog = false;
          this.$message.success('添加成功');
          this.remark = ''
        } else this.remarkDialog = false;
      });
    },

    // 变更记录
    onChange() {
      this.$_register.get('/api/recruit/account-manage/get-info-change-log', {
        params: {
          info_id: this.$route.query.id,
        },
      }).then(res => {
        JSON.stringify(res.data.data) !== '[]' ? this.list = JSON.parse(JSON.stringify(res.data.data.list).replace(/info_change/g, 'change')) : this.list = []
        this.changeDialog = true;
      });
    },

    // 查看协议
    onSkip() {
      if (this.data.agreement_sn_addr) window.open(this.data.agreement_sn_addr)
      else this.$message.warning('合同正在签署中，请查看')
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    this.$_register.get('api/recruit/account-manage/get-signed-detail?sign_info_id=' + this.$route.query.id + '&order_id=' + this.$route.query.order_id).then(res => {
      this.data = res.data.data;

    });

  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getData();
  },
};
</script>
<style lang='scss' scoped>
.details{
  ::v-deep .el-form-item{
    margin:26.5rem 0;
    .el-form-item__label,.el-form-item__content{
      line-height: 1;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
.el-input, .el-select {
  width: 217rem !important;
  margin: 0 6rem;
}

.left_item {
  display: block;
  width: 170rem;
  text-align: end;
  padding-right: 24rem;
  color: #666;
}

.el-col {
  display: flex;
  align-items: center;
}

.footer {
  margin-top: 48rem;
}

::v-deep .el-step__head {
  color: #000;
  border-color: #000
}

::v-deep .border-color {
  color: #000;
  border-color: #000
}

::v-deep .el-step__title {
  color: #000;
  border-color: #000
}

.card {
  margin-top: 25rem;
  border-top: 1rem solid #E8EDFF;
  //min-width: 1150rem;
}

.history-box {
  .step-box {
    position: relative;
  }

  .cir {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 15rem;
    height: 15rem;
    background-color: #1C4EFD;
    border-radius: 50%;
    z-index: 1;
  }

  .cir-line {
    position: absolute;
    top: 15rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 100%;
    background-color: #ccc;
  }

  .div1 > div {
    word-break: keep-all;
    white-space: nowrap;
    padding-right: 40rem;
    height: 66rem;
    line-height: 66rem;
    padding-left: 20rem;
    color: #333;
  }

  &:last-child {
    .cir-line {
      display: none;
    }
  }
}

//@media screen and (max-width: 1470rem) {
//  .card {
//    width: 1470rem;
//  }
//}
</style>
